<template>
  <header>
    <div class="py-3 px-5 flex flex-row space-x-3">
      <img src="../assets/logo.png" class="w-16" alt="" />
      <div class="flex flex-col my-auto font-semibold text-xl">
        <span>NAYAVADA</span>
        <span class="ml-7">ACADEMIC</span>
      </div>
    </div>
  </header>
</template>

<script>
export default {};
</script>

<style></style>
