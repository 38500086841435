<template>
  <div id="footer" class="text-center">
    <svg
      height="100%"
      width="100%"
      id="svg"
      viewBox="0 0 1440 400"
      xmlns="http://www.w3.org/2000/svg"
      class="transition duration-300 ease-in-out delay-150"
    >
      <path
        d="M 0,400 C 0,400 0,200 0,200 C 155.06666666666666,211.60000000000002 310.1333333333333,223.20000000000002 479,213 C 647.8666666666667,202.79999999999998 830.5333333333333,170.8 993,165 C 1155.4666666666667,159.2 1297.7333333333333,179.6 1440,200 C 1440,200 1440,400 1440,400 Z"
        stroke="none"
        stroke-width="0"
        fill="#3b82f6ff"
        class="transition-all duration-300 ease-in-out delay-150"
      ></path>
    </svg>
    <div
      class="
        font-semibold
        lg:-mt-24
        md:-mt-16
        -mt-10
        p-5
        md:p-0
        text-white
        bg-blue-500
        w-full
      "
    >
      <p>© nayavadaacademic.com 2021 All rights Reserved</p>
      <p>credit: izzudin26.github.io</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style></style>
