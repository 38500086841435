<template>
  <div
    class="
      flex flex-col
      space-y-14
      md:space-y-0 md:flex-row-reverse
      items-center
      justify-center
      mx-auto
      p-20
    "
  >
    <div class="w-full items-center justify-center">
      <img src="../assets/logo.png" class="md:w-1/2 mx-auto" alt="" />
    </div>
    <div class="flex flex-col w-full items-center justify-center space-y-4">
      <span class="text-4xl font-semibold">Selamat Datang</span>
      <div
        class="
          lg:w-1/2
          w-full
          text-justify
          md:text-left
          mx-auto
          font-semibold
          text-xl
        "
      >
        <p>
          NAYAVADA ACADEMIC merupakan sebuah kursus private teknologi secara
          tatap muka online yang bertujuan untuk mengajarkan materi teknologi
          sebagai dasar dari bidang tertentu.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Main",
};
</script>

<style></style>
